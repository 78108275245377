<template>
  <main class="TransportersForm">
    <TheSubHeader
      icon="truck-delivery"
      title="Cadastro da transportadora"
      page-title-tag="Transporters Form"
      type="tabs"
      :tabs="tab"
      organizer-gap="1rem"
      @changeTab="changeCurrentTab($event)"
    >
      <template #afterButtons>
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Transporters Form', 'back')"
          @click="$router.push('/transporters')"
        />
      </template>
      <template #filter>
        <section v-if="currentTab === 0" class="tab1">
          <DynamicForm
            ref="form"
            class="TransportersForm__form"
            :model="form"
            :blueprint="blueprint({isEdit: !!transporterId, allowToggleStatus: permissions.transportersDetail.transportersInative})"
            :endpoint-errors="endpointErrors"
            @setAddress="setAddress"
            @submit="onSubmit"
          />
        </section>
        <section v-if="currentTab === 1" class="tab1">
          <div class="TransportersForm__bindFilters">
            <BaseTreeSelect
              v-model="drivers"
              class="TransportersForm__linkDrivers"
              name="Vincular Motorista"
              label="Vincular Motorista"
              placeholder="Vincular por placa ou nome"
              multiple
              suggestions="searchDriversTransporterNot"
            />
            <BaseButton
              label="Vincular"
              class="TransportersForm__BindBtn"
              theme="simple"
              filled
              color="primary-base"
              text-color="white"
              @click="bindDriver"
            />
          </div>
        </section>
      </template>
    </TheSubHeader>

    <section v-if="currentTab === 1" class="TransportersForm__drivers">
      <div class="TransportersForm__driversContent">
        <div class="TransportersForm__bind">
          <!-- Driver -->
          <BaseTreeSelect
            v-model="filters.driversSelect"
            class="TransportersForm__linkDrivers"
            name="Pesquisar Motorista"
            label="Pesquisar Motorista"
            placeholder="Pesquisar por placa ou nome"
            multiple
            suggestions="searchDriversUnbindedToTransporters"
            :suggestions-params="transporterId"
            @input="resetSearch()"
          />
          <!-- Status -->
          <BaseTreeSelect
            v-model="filters.statusSelected"
            name="Status"
            label="Status"
            placeholder="Selecione"
            :select-options="statusOpt"
            @input="resetSearch()"
          />
        </div>
        <div class="TransportersForm__table">
          <DataTable
            :header="transportersDrivers.data.headers"
            :table="transportersDrivers.data.content"
            @remove="unBindDriver"
          />
          <Pagination @page="search()" @paginate="search()" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Utility from '@/plugins/formData'
import { transporters } from '@/api'
import { mapActions, mapState } from 'vuex'
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { DataTable, DynamicForm, Pagination } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './TransportersForm.blueprint'

export default {
  name: 'TransportersForm',
  components: {
    DataTable,
    BaseTreeSelect,
    TheSubHeader,
    DynamicForm,
    Pagination,
    BaseButton
  },

  data() {
    return {
      currentTab: 0,
      tab: [
        { id: 0, title: 'Dados Básicos' },
      ],
      form: {
        type: '',
        name: '',
        razao_social: '',
        cnpj: '',
        phone: '',
        email: '',
        zip: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state_id: null,
        status_id: {},
      },
      filters: {
        driversSelect: [],
        statusSelected: null,
      },
      endpointErrors: {},
      status: {},
      drivers: [],
      statusOpt: [{ id: 0, name:'Todos' }, { id: 1, name:'Ativo' }, { id: 2, name:'Inativo' }]
    }
  },
  computed: {
    ...mapState({
      transportersDrivers: state => state.transporters.transportersDrivers,
      permissions: state => state.auth.permissions,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    transporterId() {
      return this.$route.params.id
    },
  },

  mounted() {
    if (this.transporterId) {
      this.tab.push({ id: 1, title: 'Motoristas Vinculados' })
      this.fetchTransporter()
      this.search()
    }
  },

  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchTransportersDrivers: 'transporters/fetchTransportersDrivers',
      bindDriversToTransporters: 'transporters/bindDriversToTransporters',
      unBindDriversToTransporters: 'transporters/unBindDriversToTransporters',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    changeCurrentTab(e) {
      this.currentTab = e.id
    },

    setAddress(data) {
      this.form = {...this.form, ...data}
    },

    async fetchTransporter() {
      await transporters.fetchTransporter({ id: this.transporterId }, (res) => {
        res = {
          ...res,
          ...res.info,
          state_id: res.state_id,
          type: res?.type?.name
        }
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
      }, (e) => e)
    },

    async createTransporter(body) {
      this.setLoading(true)
      let form = body
      form.append('status_id', '1')
      await transporters.createTransporter(form, () => this.$router.push('/transporters'), (e) => {
        this.endpointErrors = e.validation
        this.setLoading(false)
      })
    },

    async updateTransporter(body) {
      this.setLoading(true)
      await transporters.updateTransporter({ id: this.transporterId, body }, () => this.$router.push('/transporters'), (e) => {
        this.endpointErrors = e.validation
        this.setLoading(false)
      })
      
    },

    async onSubmit(formData) {
      this.transporterId ? this.updateTransporter(formData) : this.createTransporter(formData)
    },

    async bindDriver() {
      const formData = Utility.convertModelToFormData({
        transporter_id: this.transporterId,
        drivers: this.drivers
      })
      await this.bindDriversToTransporters(formData)
      this.drivers = []
    },

    async unBindDriver({id}) {
      const params = {
        transporterId: this.transporterId,
        driverId: id
      }
      await this.unBindDriversToTransporters(params)
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search(){

      const params = {
        obj: {
          id: this.filters.driversSelect,
          status_id: this.filters.statusSelected ? this.filters.statusSelected.id : null
        },
        id: this.transporterId,
        paginate: this.paginate,
        page: this.page
      }

      this.fetchTransportersDrivers(params)
        .then(() => this.setPagination(this.transportersDrivers))
    }
  }
}
</script>

<style lang="scss" scoped>
.TransportersForm {
  &__content,
  &__drivers {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__drivers {
    margin-top: -30px;
    margin-bottom: 60px;
  }

  &__driversContent{
    grid-area: main;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__bindFilters {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }

  &__BindBtn {
    max-width: 200px;
  }

  &__bind{
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: end;
    grid-gap: 30px;
    margin-top: 25px;
  }

  &__form {
    grid-row-gap: 2rem;
    grid-template-areas:
      "name name name name razao_social razao_social razao_social razao_social cnpj cnpj cnpj cnpj"
      "email email email email phone phone phone phone . . . ."
      "address address address address address address address address address address address address"
      "type type type type type type type status_id status_id status_id confirm confirm";
  }

  &__form {
    padding: 10px;
  }

  &__table{
    margin-top: 30px;
  }
}
</style>