export default ({isEdit, allowToggleStatus}) => {
  return [
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome',
        invalidLabel: 'Insira um nome'
      }
    },
    {
      id: 'razao_social',
      model: 'razao_social',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Razão social',
        invalidLabel: 'Insira a razão social'
      }
    },
    {
      id: 'cnpj',
      model: 'cnpj',
      tag: 'InputGroup',
      rules: ['required', 'cnpj'],
      props: {
        label: 'CNPJ',
        mask: 'cnpj',
        invalidLabel: 'Insira um CNPJ'
      }
    },
    {
      id: 'phone',
      model: 'phone',
      tag: 'InputGroup',
      rules: ['phone'],
      props: {
        label: 'Celular',
        mask: 'phone',
        invalidLabel: 'Insira um número de Celular'
      }
    },
    {
      id: 'email',
      model: 'email',
      tag: 'InputGroup',
      rules: ['email'],
      props: {
        label: 'E-mail',
        invalidLabel: 'Insira um E-mail válido'
      }
    },


    {
      id: 'address',
      tag: 'InputAddress',
      props: {
        fields: {
          zip: 'zip',
          address: 'address',
          number: 'number',
          complement: 'complement',
          neighborhood: 'neighborhood',
          city: 'city',
          state_id: 'state_id'
        }
      }
    },

    {
      id: 'type',
      model: 'type',
      tag: 'BaseRadioGroup',
      rules: ['required'],
      props: {
        label: 'Tipo de operação:',
        flex: true,
        name: 'type',
        list: [
          {id: 0, name: 'Redespacho Agenciadora', value: 'redespacho_agenciadora'},
          {id: 1, name: 'Transferência Interestadual', value: 'transferencia_interestadual'}
        ]
      }
    },

    ...(isEdit && allowToggleStatus ? [{
      id: 'status_id',
      tag: 'ButtonToggle',
      model: 'status_id',
      props: {
        name: 'Transportadora'
      },
    }] : []),

    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar Alterações',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true
      }
    }
  ]
}